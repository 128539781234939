<template>
  <div class="">
    <div class="">
      <div class="form-row">
        <sgv-input-date
          label="เริ่มต้น"
          class="col-6"
          isNull
          v-model="startDate"
          :validations="[
            {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
          ]">
        </sgv-input-date>

        <sgv-input-date
          label="สิ้นสุด"
          class="col-6"
          isNull
          v-model="endDate"
          :validations="[
            {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
          ]">
        </sgv-input-date>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          label="ตั้งค่าเอกสาร"
          inline
          v-model="isDocConfig">
        </sgv-input-check>

        <sgv-input-check
          label="บันทึกเอกสาร"
          inline
          v-model="isLedger">
        </sgv-input-check>
      </div>
    </div>

    <div class="mb-3">
      <button
        type="button"
        class="btn btn-warning"
        @click="fetchData">
        ค้นหา
      </button>

      <ListTrialBalanceExcel
        class="ml-2"
        v-if="accountsMapped.length > 0 && startDate && endDate"
        :items="accountsMapped"
        :startDate="startDate"
        :endDate="endDate">
      </ListTrialBalanceExcel>
    </div>

    <sgv-table
      :rKey="rKey"
      :items="accountsMapped"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage"
      :toolbars="toolbars">

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code" class="no-wrap">
            <router-link
              class="text-decoration-none"
              :to="toDetail(item.id)">
              {{item.code}}
            </router-link>
          </td>
          <td v-if="hidden.name">
            <span v-if="item.level === 0" class="no-wrap">
              <u>{{item.name}}</u>
            </span>
            <span v-else-if="!item.isDone" class="no-wrap">
              <span
                v-for="n in item.level"
                :key="n"
                class="pl-3">
              </span>
              <u>{{item.name}}</u>
            </span>
            <span v-else class="no-wrap">
              <span
                v-for="n in item.level"
                :key="n"
                class="pl-3">
              </span>
              <span>- {{item.name}}</span>
            </span>
            <div v-if="item.docConfigs.length > 0 && isDocConfig">
              <small class="text-info">
                <span v-if="item.level > 0">
                  <span
                    v-for="n in item.level"
                    :key="n"
                    class="pl-3">
                  </span>
                </span>
                <span>ตั้งค่า: </span>
                <router-link
                  v-for="(docType,idx) in item.docConfigs" :key="idx"
                  class="text-decoration-none mr-1 text-info"
                  :to="toDoc(docType)">
                  {{docType}}
                </router-link>
              </small>
            </div>
            <div v-if="item.ledgers.length > 0 && isLedger">
              <small class="text-warning">
                <span v-if="item.level > 0">
                  <span
                    v-for="n in item.level"
                    :key="n"
                    class="pl-3">
                  </span>
                </span>
                <span>บันทึก: </span>
                <router-link
                  v-for="(docType,idx) in item.ledgers" :key="idx"
                  class="text-decoration-none mr-1 text-warning"
                  :to="toDoc(docType)">
                  {{docType}}
                </router-link>
              </small>
            </div>
          </td>
          <td v-if="hidden.amountDebit" class="text-success no-wrap">
            <span v-if="item.amountDebit">{{item.amountDebit | comma}}</span>
            <span v-else>-</span>

            <div v-if="!item.isDone && item.totalDebit" class="text-info">
              ({{item.totalDebit | comma}})
            </div>
          </td>
          <td v-if="hidden.amountCredit" class="text-danger no-wrap">
            <span v-if="item.amountCredit">{{item.amountCredit | comma}}</span>
            <span v-else>-</span>

            <div v-if="!item.isDone && item.totalCredit" class="text-info">
              ({{item.totalCredit | comma}})
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import ListTrialBalanceExcel from './ListTrialBalanceExcel.vue'
import { LIST_TRIAL_BALANCE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    accountType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `Account${this.$form.capitalize(this.accountType)}Detail`,
      rKey: `Account${this.$form.capitalize(this.accountType)}ListTrialBalance`,
      rFields: ['filter', 'options', 'startDate', 'endDate', 'isDocConfig', 'isLedger'],
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'เดบิต', value: 'amountDebit'},
        {text: 'เครดิต', value: 'amountCredit'}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'amountDebit', 'amountCredit'],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        {text: 'ทั้งหมด', value: null}
      ],
      toolbars: [
        {value: 'filter', icon: 'cog', class: 'text-warning'}
      ],
      accounts: [],
      startDate: '',
      endDate: '',
      isDocConfig: false,
      isLedger: false,
    }
  },
  validations: {
    startDate: { required },
    endDate: { required },
  },
  computed: {
    accountsMapped () {
      return this.accounts
      .filter(acc => !acc.parentId)
      .reduce((t, child) => {
        const children = this.getChildren(child.id, 0, this.accounts.filter(x => x.parentId === child.id))
        return [
          ...t,
          {
            id: child.id,
            code: child.code,
            name: child.name,
            amountDebit: child.amountDebit,
            amountCredit: child.amountCredit,
            totalDebit: child.totalDebit,
            totalCredit: child.totalCredit,
            level: 0,
            isDone: children.length === 0,
            docConfigs: child.docConfigs,
            ledgers: child.ledgers,
          },
          ...children
        ]
      }, [])
    }
  },
  methods: {
    getChildren (parentId, level, children) {
      if (children.length === 0) return []

      return this.accounts
      .filter(acc => acc.parentId === parentId)
      .reduce((t,child) => {
        const arr = this.getChildren(child.id, level+1, this.accounts.filter(x => x.parentId === child.id))
        return [
          ...t,
          {
            id: child.id,
            code: child.code,
            name: child.name,
            amountDebit: child.amountDebit,
            amountCredit: child.amountCredit,
            totalDebit: child.totalDebit,
            totalCredit: child.totalCredit,
            level: level+1,
            isDone: arr.length === 0,
            docConfigs: child.docConfigs,
            ledgers: child.ledgers,
          },
          ...arr
        ]
      }, [])
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {accountId: id}
      }
    },
    toDoc (docType) {
      return {
        name: `Doc${this.$form.capitalize(docType)}List`
      }
    },
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()

      this.accounts = []

      this.$apollo.query({
        query: LIST_TRIAL_BALANCE(this.templateType),
        variables: {
          accountType: this.accountType,
          startDate: this.startDate,
          endDate: this.endDate
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.accounts = res.data.items
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  watch: {
    startDate: 'setRetaining',
    endDate: 'setRetaining',
    isDocConfig: 'setRetaining',
    isLedger: 'setRetaining'
  },
  components: {
    ListTrialBalanceExcel
  }
}
</script>

<style lang="css" scoped>
strong {
  font-weight: bold;
}
</style>
