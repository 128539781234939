<template>
  <sgv-table
    :rKey="rKey"
    :items="accounts"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options"
    :rowsPerPage="rowsPerPage"
    :toolbars="toolbars">

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none"
            :to="toDetail(item.id)">
            {{item.code}}
          </router-link>
        </td>
        <td v-if="hidden.name">{{ item.name }}</td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`account:${accountType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_ACCOUNT } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    accountType: {
      type: String,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      detailView: `Account${this.$form.capitalize(this.accountType)}Detail`,
      rKey: `Account${this.$form.capitalize(this.accountType)}ListTable`,
      rFields: ['filter', 'options', 'ledgerFormdata'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      accounts: [],
      rowsPerPage: [
        {text: '10', value: 10},
        {text: '25', value: 25},
        {text: '50', value: 50},
      ],
      toolbars: [
        {value: 'filter', icon: 'cog', class: 'text-warning'}
      ]
    }
  },
  apollo: {
    accounts: {
      query () {
        return LIST_ACCOUNT(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          accountType: this.accountType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      if (this.categoryId === -1) {
        filter.params.isActive = false
        delete filter.params.categoryId
      } else {
        filter.params.isActive = true
        filter.params.categoryId = this.categoryId
      }
      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {accountId: id}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
