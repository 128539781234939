<template>
  <div class="">
    <div class="form-row">
      <sgv-input-file
        class="col-12"
        ref="fileUpload"
        label="ไฟล์ CSV"
        @change="changeToJSON($event)">
      </sgv-input-file>
    </div>

    <button
      class="btn btn-primary"
      type="button"
      @click="reset">
      ยกเลิก
    </button>

    <sgv-csv
      :items="csvData"
      :labels="csvLabels"
      :filename="csvFilename">
      <button
        type="button"
        class="btn btn-info ml-2">
        ตัวอย่าง CSV
      </button>
    </sgv-csv>

    <div class="mt-3" v-if="duplicateRows.length > 0">
      <span class="text-danger">ชื่อบัญชีซ้ำ:</span>
      <sgv-table
        :items="duplicateRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">{{item.code}}</td>
            <td v-if="hidden.name">{{item.name}}</td>
            <td v-if="hidden.parent">{{item.parent}}</td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="addRows.length > 0">
      <button
        type="button"
        class="btn btn-success btn-sm mb-2"
        @dblclick="addAll">
        เพิ่มทั้งหมด
      </button>
      <sgv-table
        :items="addRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">
              <button
                type="button"
                class="btn btn-success btn-sm mr-1"
                @click="addData(item)">
                เพิ่ม
              </button>
              {{item.code}}
            </td>
            <td v-if="hidden.name">{{item.name}}</td>
            <td v-if="hidden.parent">
              {{item.parent ? item.parent.code : ''}}
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="parentRows.length > 0">
      <span class="text-danger">ไม่พบ Parent:</span>
      <sgv-table
        :items="parentRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.code">{{item.code}}</td>
            <td v-if="hidden.name">{{item.name}}</td>
            <td v-if="hidden.parent">{{item.parent}}</td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_IMPORT_VALIDATION,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    accountType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Account${this.$form.capitalize(this.accountType)}ListImport`,
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'parent', value: 'parent'}
      ],
      options: {
        headers: ['code', 'name', 'parent'],
        column: null,
        search: null,
        toolbar: null,
      },
      csvFilename: 'ตัวอย่างนำเข้าบัญชี',
      csvLabels: {
        master: { title: 'master' },
        child1: { title: 'child1' },
        child2: { title: 'child2' },
        child3: { title: 'child3' },
        name: { title: 'name' },
      },
      csvData: [
        {
          master: '100000',
          child1: '',
          child2: '',
          child3: '',
          name: 'สินทรัพย์',
        },
        {
          master: '',
          child1: '110000',
          child2: '',
          child3: '',
          name: 'สินทรัพย์หมุนเวียน',
        },
        {
          master: '',
          child1: '',
          child2: '111000',
          child3: '',
          name: 'เงินสดและเงินฝากธนาคาร',
        },
        {
          master: '',
          child1: '',
          child2: '',
          child3: '111100',
          name: 'เงินสด',
        },
        {
          master: '',
          child1: '',
          child2: '111200',
          child3: '',
          name: 'เงินฝากธนาคาร',
        },
        {
          master: '',
          child1: '',
          child2: '',
          child3: '111210',
          name: 'บัญชีเงินฝากกระแสรายวัน',
        },
      ],
      duplicateRows: [],
      parentRows: [],
      addRows: [],
      updateRows: [],
      inputRows: []
    }
  },
  methods: {
    changeToJSON (e) {
      var fileInput = e.target.files[0]
      const options = {
        header: false,
        skipEmptyLines: true,
        complete: (results) => {
          this.inputRows = this.cleanupInput(results.data.slice(1))
          this.getValidation()
        }
      }
      this.$papa.parse(fileInput, options)
    },
    cleanupInput (input) {
      const rows = []
      for (var i = 0; i < input.length; i++) {
        const cols = input[i].slice(0, -1)
        const name = input[i][cols.length].trim()

        const firstIndex = cols.findIndex(c => c.trim())

        if (name && firstIndex === 0) {
          rows.push({
            code: cols[firstIndex].trim(),
            name,
            parent: null
          })
        } else {
          let parent
          for (var j = i; j > 0; j--) {
            const prevRow = input[j-1]
            const prevCols = prevRow.slice(0, -1)
            const prevFirstIndex = prevCols.findIndex(c => c.trim())

            if (prevFirstIndex < firstIndex) {
              parent = prevCols[prevFirstIndex].trim()
              break
            }
          }

          if (!parent) throw new Error('PARENT_NOT_FOUND')

          rows.push({
            code: cols[firstIndex].trim(),
            name,
            parent
          })
        }
      }

      return rows
    },
    reset () {
      this.setDefault ()
      this.$refs.fileUpload.reset()
    },
    getValidation: debounce(function() {
      this.$apollo.query({
        query: LIST_IMPORT_VALIDATION(this.templateType),
        variables: {
          accountType: this.accountType,
          input: this.inputRows
        },
        fetchPolicy: 'no-cache',
      })
      .then(result => {
        this.duplicateRows = result.data.result?.duplicates || []
        this.parentRows = result.data.result?.parents || []
        this.addRows = result.data.result?.adds || []
        this.updateRows = result.data.result?.updates || []
      })
      .catch(err => {
        this.setDefault ()
        this.$toasted.global.error(err)
      })
    }, 300),
    setDefault() {
      this.duplicateRows = []
      this.parentRows = []
      this.addRows = []
      this.updateRows = []
    },
    serializeInput (item) {
      return {
        code: item.code,
        name: item.name,
        parentId: item.parentId
      }
    },
    addData (item) {
      this.$apollo.mutate({
        mutation: CREATE_ACCOUNT(this.templateType),
        variables: {
          accountType: this.accountType,
          input: this.serializeInput(item)
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.getValidation()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData (item) {
      this.$apollo.mutate({
        mutation: UPDATE_ACCOUNT(this.templateType),
        variables: {
          accountType: this.accountType,
          accountId: item.id,
          input: this.serializeInput(item)
        }
      })
      .then(() => {
        this.$toasted.global.success("อัพเดทสำเร็จ")
        this.getValidation()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    async addAll () {
      const items = this.addRows
      for (let i = 0; i < items.length; i++) {
        await this.addData(items[i])
      }
    },
    async updateAll () {
      const items = this.updateRows
      for (let i = 0; i < items.length; i++) {
        await this.updateData(items[i])
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
