<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <Category
            :options="categories"
            :accountType="accountType"
            :templateType="templateType"
            v-model="categoryId">
          </Category>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListImport
      v-if="categoryId === -3"
      :accountType="accountType"
      :templateType="templateType"
      :group="group">
    </ListImport>

    <ListTrialBalance
      v-else-if="categoryId === -4"
      :accountType="accountType"
      :templateType="templateType">
    </ListTrialBalance>

    <ListTable
      v-else
      :group="group"
      :categoryId="categoryId"
      :accountType="accountType"
      :templateType="templateType">
    </ListTable>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import ListTable from './ListTable.vue'
import Category from './Category.vue'
import ListImport from './ListImport.vue'
import ListTrialBalance from './ListTrialBalance.vue'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.title}`
    }
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    accountType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Account${this.$form.capitalize(this.accountType)}List`,
      rFields: ['categoryId'],
      categoryId: 0,
      categories: [
        {text: 'งบทดลอง', value: -4},
        {text: 'นำเข้าข้อมูล', value: -3, role: `account:${this.accountType}:import`},
      ]
    }
  },
  watch: {
    categoryId () {
      this.setRetaining()
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListTable,
    Category,
    ListImport,
    ListTrialBalance
  }
}
</script>

<style lang="css" scoped>
</style>
