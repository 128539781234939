<template>
  <button
    @click="download"
    class="btn btn-success">
    <fa icon="download"></fa>
    Excel
  </button>
</template>

<script>
import round from 'lodash/round'
import xlsx from 'json-as-xlsx'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      sheetname: '000000',
      columns: [
        {label: 'รหัส', value: 'code'},
        {label: 'บัญชี', value: 'name'},
        {label: 'เดบิต', value: 'amountDebit'},
        {label: 'เครดิต', value: 'amountCredit'},
        {label: 'ยอดรวมเดบิต', value: 'totalDebit'},
        {label: 'ยอดรวมเครดิต', value: 'totalCredit'},
      ]
    }
  },
  computed: {
    filename () {
      return `งบทดลอง__${this.startDate}_${this.endDate}`
    },
    rows () {
      const content = this.items.map(v => {
        const indent = new Array(v.level).fill('----').join('')
        return {
          code : v.code,
          name : `${indent} ${v.name}`,
          amountDebit : round(v.amountDebit, 2),
          amountCredit : round(v.amountCredit, 2),
          totalDebit : round(v.totalDebit, 2),
          totalCredit : round(v.totalCredit, 2),
        }
      })

      const data = [{
        sheet: this.sheetname,
        columns: this.columns,
        content
      }]

      return data
    }
  },
  methods: {
    download () {
      const settings = {
        fileName: this.filename,
      }
      xlsx(this.rows, settings)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
