var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-file',{ref:"fileUpload",staticClass:"col-12",attrs:{"label":"ไฟล์ CSV"},on:{"change":function($event){return _vm.changeToJSON($event)}}})],1),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.reset}},[_vm._v(" ยกเลิก ")]),_c('sgv-csv',{attrs:{"items":_vm.csvData,"labels":_vm.csvLabels,"filename":_vm.csvFilename}},[_c('button',{staticClass:"btn btn-info ml-2",attrs:{"type":"button"}},[_vm._v(" ตัวอย่าง CSV ")])]),(_vm.duplicateRows.length > 0)?_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"text-danger"},[_vm._v("ชื่อบัญชีซ้ำ:")]),_c('sgv-table',{attrs:{"items":_vm.duplicateRows,"headers":_vm.headers,"options":_vm.options,"headerless":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var hidden = ref.hidden;
return [_c('tr',{},[(hidden.code)?_c('td',[_vm._v(_vm._s(item.code))]):_vm._e(),(hidden.name)?_c('td',[_vm._v(_vm._s(item.name))]):_vm._e(),(hidden.parent)?_c('td',[_vm._v(_vm._s(item.parent))]):_vm._e()])]}}],null,false,4168691870)})],1):_vm._e(),(_vm.addRows.length > 0)?_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-success btn-sm mb-2",attrs:{"type":"button"},on:{"dblclick":_vm.addAll}},[_vm._v(" เพิ่มทั้งหมด ")]),_c('sgv-table',{attrs:{"items":_vm.addRows,"headers":_vm.headers,"options":_vm.options,"headerless":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var hidden = ref.hidden;
return [_c('tr',{},[(hidden.code)?_c('td',[_c('button',{staticClass:"btn btn-success btn-sm mr-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.addData(item)}}},[_vm._v(" เพิ่ม ")]),_vm._v(" "+_vm._s(item.code)+" ")]):_vm._e(),(hidden.name)?_c('td',[_vm._v(_vm._s(item.name))]):_vm._e(),(hidden.parent)?_c('td',[_vm._v(" "+_vm._s(item.parent ? item.parent.code : '')+" ")]):_vm._e()])]}}],null,false,148512698)})],1):_vm._e(),(_vm.parentRows.length > 0)?_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"text-danger"},[_vm._v("ไม่พบ Parent:")]),_c('sgv-table',{attrs:{"items":_vm.parentRows,"headers":_vm.headers,"options":_vm.options,"headerless":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var hidden = ref.hidden;
return [_c('tr',{},[(hidden.code)?_c('td',[_vm._v(_vm._s(item.code))]):_vm._e(),(hidden.name)?_c('td',[_vm._v(_vm._s(item.name))]):_vm._e(),(hidden.parent)?_c('td',[_vm._v(_vm._s(item.parent))]):_vm._e()])]}}],null,false,4168691870)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }