import { render, staticRenderFns } from "./ListImport.vue?vue&type=template&id=6018a274&scoped=true&"
import script from "./ListImport.vue?vue&type=script&lang=js&"
export * from "./ListImport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6018a274",
  null
  
)

export default component.exports